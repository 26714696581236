import type { Woosb } from '@/lib/graphql/transformers/product';
import type { ItemArray } from '@/types';

export enum BundleAction {
  ADD_PRODUCT,
  SUBTRACT_PRODUCT,
  UPDATE_PRODUCT,
  REMOVE_PRODUCT,
  RESET_BUNDLE,
  CHANGE_LIMIT_WHOLE,
  LOAD_INITIAL_STATE,
}

export type TypeBundleAction =
  | {
      type: BundleAction.ADD_PRODUCT;
      payload: {
        productID: number;
        quantity?: number;
      };
    }
  | {
      type: BundleAction.SUBTRACT_PRODUCT;
      payload: {
        productID: number;
      };
    }
  | {
      type: BundleAction.UPDATE_PRODUCT;
      payload: {
        productID: number;
        quantity: number;
      };
    }
  | {
      type: BundleAction.REMOVE_PRODUCT;
      payload: {
        productID: number;
      };
    }
  | {
      type: BundleAction.RESET_BUNDLE;
    }
  | {
      type: BundleAction.CHANGE_LIMIT_WHOLE;
      payload: {
        variant: ItemArray<Woosb['variants']>;
      };
    }
  | {
      type: BundleAction.LOAD_INITIAL_STATE;
      payload: {
        state: TypeBundle;
      };
    };

export type TypeBundleDispatch = {
  add: (productID: number) => void;
  subtract: (productID: number) => void;
  update: (productID: number, quantity: number) => void;
  remove: (productID: number) => void;
  reset: () => void;
  changeVariant: (variant: ItemArray<Woosb['variants']>) => void;
  helpers: {
    addDisabled: (productID: number) => boolean;
    subtractDisabled: (productID: number) => boolean;
    disabled: (productID: number) => boolean;
  };
};

export type TypeBundle = Omit<Woosb, 'products'> & {
  products: Map<number, ItemArray<Woosb['products']>>;
  initialProducts: Woosb['products'];
  selectedProducts: Woosb['products'];
  numProducts: number;
  woobsIds: string;
  totalPrice: string;
  totalRegularPrice: string;
  selectedVariant: ItemArray<Woosb['variants']>;
  loading?: boolean;
};
