import { oneLine } from 'common-tags';

import { ConditionalWrapper } from '@/hoc';
import { Badge, Container, NextLink } from '@/atoms';
import { Box } from '@/molecules';

import {
  isExternal,
  isOutOfStock,
  pickChild,
  pickChilds,
  trackAddToCartClick,
  trackGoToProductClick,
} from '@/lib/utils';
import * as childs from './childs';
import { Button } from './childs/Button';

import type { FunctionComponent } from 'react';
import type { TypeCardProductProps } from './types';

// TODO añadir soporte para multiples imagenes
// TODO extraer contain y cover a Wordpress
/**
 * CardProduct sirve para hacer las card de producto
 */
export const CardProduct: FunctionComponent<TypeCardProductProps> = ({
  className = '',
  type,
  href,
  productID,
  box,
  stock,
  children,
  onClick,
  hit,
  sendEvent,
  editable = true,
  isConnectif = false,
  isConfigurable = false,
  hasFreeShipping = false,
  disableFreeShipping = false,
  minQtyOrder,
  woosbData,
  woosbMinQty,
}: TypeCardProductProps): JSX.Element => {
  const [others, badge] = pickChilds(children, Badge);
  const [others2, image] = pickChild(others, childs.ImageCustom);
  const [others3, category] = pickChild(others2, childs.Category);
  const [others4, name] = pickChild(others3, childs.Name);
  const [others5, seller] = pickChild(others4, childs.Seller);
  const [others6, priceRegular] = pickChild(others5, childs.PriceRegular);
  const [othersChildren, priceDiscounted] = pickChild(
    others6,
    childs.PriceDiscounted,
  );
  const disabled =
    productID === undefined ||
    (!isExternal(type) && !!stock && isOutOfStock(stock));

  return (
    <Container
      className={oneLine`h-full overflow-hidden outline outline-1 outline-primary-200 ${className} `}
      backgroundColor="bg-bg-secondary"
      padding="p-0"
      justify="justify-start"
      align="items-start"
      testid="cardProduct"
    >
      <NextLink href={href}>
        <a
          className="relative m-2 h-[166px] w-[calc(100%_-_(2_*_8px))] overflow-hidden"
          aria-label={
            typeof children === 'string' ? name?.props.children : 'Ver producto'
          }
          data-playwright="lnk_see-product"
          onClick={() => {
            onClick && onClick();
            isConnectif &&
              productID &&
              trackGoToProductClick(productID.toString());
            sendEvent && hit && sendEvent('click', hit, 'Product Clicked');
          }}
          style={
            image?.props?.backgroundColor && {
              backgroundColor: image.props.backgroundColor,
            }
          }
        >
          <ConditionalWrapper
            condition={Boolean(box)}
            wrapper={(children) => (
              <div className="relative mx-auto h-full max-w-[166px]">
                <div className="relative -ml-8 h-full w-full">{children}</div>

                {box && (
                  <Box
                    amount={`x${box.amount}`}
                    {...(box.type ? { type: box.type } : {})}
                    {...(box.size ? { size: box.size } : {})}
                    className={oneLine`absolute bottom-3 right-2 z-10 ${box.className ?? ''} `}
                  />
                )}
              </div>
            )}
          >
            {image}
          </ConditionalWrapper>

          {badge && (
            <div className="absolute right-0 top-1 flex flex-col items-end gap-2">
              {badge}
            </div>
          )}
        </a>
      </NextLink>

      <div
        className="flex w-full grow flex-col justify-between p-3"
        onClick={() => {
          isConnectif &&
            productID &&
            trackGoToProductClick(productID.toString());
          sendEvent && hit && sendEvent('click', hit, 'Product Clicked');
        }}
      >
        <div className="mb-3 flex flex-col gap-1">
          {category}

          <h3>
            <NextLink href={href}>
              <a
                onClick={onClick}
                className="u-font-card line-clamp-3 normal-case text-primary-900"
              >
                {name}
              </a>
            </NextLink>
          </h3>

          {seller}
        </div>

        <div>
          <div className="flex items-end justify-between gap-2">
            {(priceRegular || priceDiscounted) && (
              <ConditionalWrapper
                condition={disabled || Boolean(priceRegular?.props?.children)}
                wrapper={(children) => (
                  <div
                    className={oneLine`flex gap-2 ${editable ? 'flex-col' : 'flex-row-reverse'} `}
                  >
                    {disabled && (
                      <b className="u-body u-body--xs uppercase">Sin stock</b>
                    )}
                    {!disabled && priceRegular}
                    {children}
                  </div>
                )}
              >
                {priceDiscounted && (
                  <priceDiscounted.type
                    {...priceDiscounted.props}
                    disabled={disabled}
                    href={href}
                  />
                )}
              </ConditionalWrapper>
            )}

            {editable && (
              <Button
                disabled={disabled}
                href={href}
                type={type}
                hit={hit}
                productID={productID}
                sendEvent={sendEvent}
                isConfigurableBundle={isConfigurable}
                stopPropagation={isConnectif}
                woosbData={woosbData}
                woosbMinQty={woosbMinQty}
                isConnectif={isConnectif}
                {...(minQtyOrder && {
                  quantity: minQtyOrder,
                })}
                {...(isConnectif &&
                  productID && {
                    onClick: () => {
                      trackAddToCartClick(productID.toString());
                      sendEvent &&
                        hit &&
                        sendEvent('click', hit, 'Product Clicked');
                    },
                  })}
              />
            )}
          </div>

          {!disableFreeShipping && (
            <span
              className={`u-body--xs mt-1 text-secondary-500 ${hasFreeShipping ? '' : 'opacity-0'}`}
            >
              Envío gratuito
            </span>
          )}
        </div>
      </div>

      {othersChildren}
    </Container>
  );
};
