import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { BoxQuantity } from '@/atoms';
import { Button } from '@/molecules';

import { reducer } from './reducer';
import { BundleAction } from './types';
import {
  clearCurrentBundle,
  getCurrentBundle,
  initialState,
  saveCurrentBundle,
} from './utils';

import type { Woosb } from '@/lib/graphql/transformers/product';
import type { ItemArray, PropsWithChildren } from '@/types';
import type { TypeBundle, TypeBundleDispatch } from './types';

const Modal = dynamic(() => import('@/molecules/modal'), {
  ssr: false,
});

export const BundleContext = createContext<TypeBundle | undefined>(undefined);
export const BundleDispatchContext = createContext<
  TypeBundleDispatch | undefined
>(undefined);

export const BundleProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: Woosb }>) => {
  const [state, dispatch] = useReducer(reducer, config, initialState);
  const [showUpsell, setShowUpsell] = useState(false);
  const actualVariantIndex = state.variants.findIndex(
    (variant) => variant.quantity === state.selectedVariant.quantity,
  );
  const nextVariant = state.variants.at(actualVariantIndex + 1);
  const currentProductID = useRef<null | number>(null);

  const router = useRouter();

  useEffect(() => {
    if (!state.loading) saveCurrentBundle(state);
  }, [state]);

  useEffect(() => {
    const currentBundle = getCurrentBundle();

    dispatch({
      type: BundleAction.LOAD_INITIAL_STATE,
      payload: {
        state: currentBundle ?? { ...initialState(config), loading: false },
      },
    });
  }, [config]);

  useEffect(() => {
    if (!!router.query?.add_product) {
      const currentQuantity = getCurrentBundle()?.numProducts ?? 0;
      let quantityToAdd = Number(router.query?.product_quantity ?? 1);
      const maxQuantity = state.limitWhole.max;

      if (maxQuantity && quantityToAdd + currentQuantity > maxQuantity) {
        quantityToAdd = maxQuantity - currentQuantity;
      }

      if (quantityToAdd === 0) return;

      // We check for every variation to find the right one, so we allow the bundle
      // to resize in any direction.
      for (let i = 0; i < state.variants.length; i++) {
        const variantSize = state.variants[i]?.quantity ?? 0;
        const newQuantity = quantityToAdd + currentQuantity;

        if (variantSize >= newQuantity) {
          dispatch({
            type: BundleAction.CHANGE_LIMIT_WHOLE,
            payload: {
              variant: { quantity: state.variants[i]!.quantity },
            },
          });
          break;
        }
      }

      dispatch({
        type: BundleAction.ADD_PRODUCT,
        payload: {
          productID: Number(router.query.add_product),
          quantity: quantityToAdd,
        },
      });
    }
  }, [router]);

  const addDisabled = (productID: number) =>
    Boolean(
      (state.numProducts === state.selectedVariant?.quantity &&
        state.selectedVariant?.quantity ===
          Math.max(...state.variants.map((v) => v.quantity))) ||
        state.selectedProducts.find((p) => p.id === productID)?.quantity ===
          state.limitEach.max ||
        (state.limitWhole.max && state.numProducts >= state.limitWhole.max),
    );
  const subtractDisabled = (productID: number) =>
    state.selectedProducts.find((p) => p.id === productID)?.quantity ===
      state.limitEach.min || state.products.get(productID)?.quantity === 0;
  const disabled = (productID: number) =>
    addDisabled(productID) && subtractDisabled(productID);

  const add: TypeBundleDispatch['add'] = (productID) => {
    if (nextVariant && state.numProducts === state.selectedVariant.quantity) {
      currentProductID.current = productID;
      setShowUpsell(true);
      return;
    }

    dispatch({
      type: BundleAction.ADD_PRODUCT,
      payload: {
        productID,
      },
    });
  };

  const subtract: TypeBundleDispatch['subtract'] = (productID) =>
    dispatch({
      type: BundleAction.SUBTRACT_PRODUCT,
      payload: {
        productID,
      },
    });

  const update: TypeBundleDispatch['update'] = (productID, quantity) =>
    dispatch({
      type: BundleAction.UPDATE_PRODUCT,
      payload: {
        productID,
        quantity,
      },
    });

  const remove: TypeBundleDispatch['remove'] = (productID) =>
    dispatch({
      type: BundleAction.REMOVE_PRODUCT,
      payload: {
        productID,
      },
    });

  const reset: TypeBundleDispatch['reset'] = () => {
    dispatch({
      type: BundleAction.RESET_BUNDLE,
    });

    clearCurrentBundle();
  };

  const changeVariant: TypeBundleDispatch['changeVariant'] = (
    variant: ItemArray<Woosb['variants']>,
  ) =>
    dispatch({
      type: BundleAction.CHANGE_LIMIT_WHOLE,
      payload: {
        variant,
      },
    });

  return (
    <BundleContext.Provider value={state}>
      <BundleDispatchContext.Provider
        value={{
          add,
          subtract,
          update,
          remove,
          reset,
          changeVariant,
          helpers: {
            addDisabled,
            subtractDisabled,
            disabled,
          },
        }}
      >
        {children}

        {nextVariant && (
          <Modal
            open={showUpsell}
            setOpen={setShowUpsell}
            unmount={true}
            maxWidth="max-w-xs"
          >
            <div className="flex flex-col justify-center text-center">
              <p className="u-headline u-headline@mobile--h2 mb-3">
                ¿Necesitas un pack más grande?
              </p>

              <p className="u-body u-body--s mb-8">
                Cambia a un pack mayor para seguir añadiendo productos
              </p>

              <div className="relative mb-8 flex items-end self-center">
                <BoxQuantity variant="gray" className="z-10 -mr-3" width={60}>
                  {state.selectedVariant.quantity}
                </BoxQuantity>

                <BoxQuantity variant="orange" width={80}>
                  {nextVariant?.quantity}
                </BoxQuantity>
              </div>

              <Button
                size="normal-full"
                onClick={() => {
                  nextVariant && changeVariant(nextVariant);
                  setShowUpsell(false);
                  // TODO: Refactor to use add() instead of dispatch directly
                  // The problem with add is that use the state of the moment of the first call
                  // so the state doesnt update the change variant and relaunch the modal
                  currentProductID.current &&
                    dispatch({
                      type: BundleAction.ADD_PRODUCT,
                      payload: {
                        productID: currentProductID.current,
                      },
                    });
                }}
              >
                Cambiar a pack de {nextVariant?.quantity} uds
              </Button>
            </div>
          </Modal>
        )}
      </BundleDispatchContext.Provider>
    </BundleContext.Provider>
  );
};
