import { gtmPush } from '@/lib/utils';
import { BundleError } from './BundleError';
import { BundleAction } from './types';
import {
  getNewState,
  getSelectedProducts,
  getTotalPrice,
  initialState,
  numProducts,
  woobsIds,
} from './utils';

import type { TypeBundle, TypeBundleAction } from './types';

export const reducer = (
  bundle: TypeBundle,
  action: TypeBundleAction,
): TypeBundle => {
  let productID: number | null = null;

  switch (action.type) {
    case BundleAction.ADD_PRODUCT:
      productID = action.payload.productID;

      return getNewState(
        bundle,
        productID,
        (actualQuantity) => actualQuantity + (action.payload.quantity ?? 1),
      );
    case BundleAction.SUBTRACT_PRODUCT:
      productID = action.payload.productID;

      return getNewState(bundle, productID, (actualQuantity) =>
        actualQuantity > 0 ? actualQuantity - 1 : 0,
      );
    case BundleAction.UPDATE_PRODUCT:
      const quantity = action.payload.quantity;
      productID = action.payload.productID;

      return getNewState(bundle, productID, () => quantity);
    case BundleAction.REMOVE_PRODUCT:
      productID = action.payload.productID;

      return getNewState(bundle, productID, () => 0);
    case BundleAction.RESET_BUNDLE:
      const {
        discount,
        initialProducts,
        limitEach,
        limitWhole,
        optionalProducts,
        variants,
        discountByVariant,
        autoCalculatePrice,
      } = bundle;

      return initialState(
        structuredClone({
          discount,
          products: initialProducts,
          limitEach,
          limitWhole,
          optionalProducts,
          variants,
          discountByVariant,
          autoCalculatePrice,
        }),
      );
    case BundleAction.CHANGE_LIMIT_WHOLE:
      const variant = action.payload.variant;
      const products: TypeBundle['products'] = new Map(
        bundle.initialProducts.map(({ id, ...props }) => [
          id,
          { id, ...props, quantity: 0 },
        ]),
      );
      let temp = 0;

      for (const product of bundle.selectedProducts) {
        if (variant.quantity >= temp + product.quantity) {
          products.set(product.id, { ...product });
          temp += product.quantity;
        } else if (variant.quantity < temp + product.quantity) {
          const quantity = variant.quantity - temp;
          products.set(product.id, { ...product, quantity });
          temp += quantity;
        } else if (variant.quantity < temp) {
          break;
        }
      }

      const selectedProducts = getSelectedProducts([...products.values()]);

      gtmPush({
        event: 'custom_box',
        action: 'update_custom_box',
        label: variant.quantity,
      });

      const discountVariant =
        bundle.discountByVariant?.find(
          (d) => d.quantity === variant.quantity,
        ) ?? bundle.discount;

      return {
        ...bundle,
        products,
        selectedProducts,
        numProducts: numProducts(selectedProducts),
        woobsIds: woobsIds(selectedProducts),
        // Por reglas de negocio el precio se calcula siempre del regularPrice
        // si es un bundle de cajas
        totalPrice: getTotalPrice(
          selectedProducts,
          bundle.discountByVariant && bundle.discountByVariant?.length > 0
            ? 'regularPrice'
            : 'price',
          {
            ...discountVariant,
          },
        ),
        totalRegularPrice: getTotalPrice(selectedProducts, 'regularPrice'),
        selectedVariant: variant,
        discount: {
          amount: discountVariant.amount,
          percentage: discountVariant.percentage,
          type: discountVariant.type,
        },
        loading: false,
      };
    case BundleAction.LOAD_INITIAL_STATE:
      return action.payload.state;
    default: {
      throw new BundleError(`Tipo de action desconocida: ${action}`);
    }
  }
};
